// Redux
import { configureStore } from "@reduxjs/toolkit"
import reduxThunk from "redux-thunk"
import rootReducer from "./rootReducer"

const store = configureStore({
  reducer: rootReducer,
  middleware: [reduxThunk],
})

export default store
