import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { openCart } from "./cartSlice"
import { ShoppingCart } from "react-feather"

const CartButton = () => {
  const cartCount = useSelector((state: any) =>
    state.cart.checkout?.lineItems.reduce((a, b) => a + b.quantity, 0)
  )
  const dispatch = useDispatch()
  return (
    <button
      className="flex items-center text-sm"
      onClick={() => dispatch(openCart())}
    >
      <ShoppingCart className="mr-1" />
      {!!cartCount && (
        <div className="flex items-center justify-center w-6 h-6 font-semibold rounded-full cta-gradient">
          {cartCount}
        </div>
      )}
    </button>
  )
}

export default CartButton
