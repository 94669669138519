import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  Dispatch,
} from "@reduxjs/toolkit"
import Client, { Checkout, CheckoutLineItemInput } from "shopify-buy"

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_TOKEN,
  domain: process.env.GATSBY_SHOPIFY_SHOP_URL,
} as any)

const SHOPIFY_CHECKOUT_STORAGE_KEY = "shopify_checkout_id"
const isBrowser = typeof window !== "undefined"

type InitialState = {
  checkout: Checkout
  loading: boolean
  open: boolean
  client: Client
}

const initialState: InitialState = {
  checkout: { lineItems: [] } as unknown as Checkout,
  loading: false,
  open: false,
  client,
}

const getCheckoutId = () => {
  const checkoutId = isBrowser
    ? localStorage.getItem(SHOPIFY_CHECKOUT_STORAGE_KEY)
    : null

  return checkoutId as string
}

const saveCheckout = (id: string) =>
  isBrowser && localStorage.setItem(SHOPIFY_CHECKOUT_STORAGE_KEY, id)

export const initializeCheckout = createAsyncThunk(
  "cart/initializeCheckout",
  async () => {
    try {
      const existingCheckoutId = getCheckoutId()
      let checkout = null

      if (existingCheckoutId) {
        checkout = await client.checkout.fetch(existingCheckoutId)
        if (checkout?.completedAt) checkout = null
      }
      if (!checkout) {
        checkout = await client.checkout.create()
      }

      saveCheckout(checkout.id)

      return checkout
    } catch (e) {
      console.error(e)
    }
  }
)

export const updateLineItem = createAsyncThunk(
  "cart/updateLineItem",
  async ({
    id,
    variantId,
    quantity,
  }: {
    id: string
    variantId: string
    quantity: string
  }) => {
    try {
      const checkoutId = getCheckoutId()

      const lineItemsToUpdate = [
        { id, variantId, quantity: parseInt(quantity, 10) },
      ]

      const newCheckout = await client.checkout.updateLineItems(
        checkoutId,
        lineItemsToUpdate
      )

      return newCheckout
    } catch (e) {
      console.log(e)
      throw e
    }
  }
)

export const addLineItem = createAsyncThunk(
  "cart/addLineItem",
  async (lineItem: CheckoutLineItemInput) => {
    try {
      const checkoutId = getCheckoutId()

      const lineItemsToAdd = [lineItem]
      const newCheckout = await client.checkout.addLineItems(
        checkoutId,
        lineItemsToAdd
      )

      return newCheckout
    } catch (e) {
      console.error(e)
      throw e
    }
  }
)

export const getInventory = async (productId: string, variantId: string) => {
  const fetchedProduct = await client.product.fetch(productId)
  const result =
    fetchedProduct?.variants.filter(variant => variant.id === variantId) ?? []

  if (result.length > 0) {
    return result[0].quantityAvailable
  }
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    loadingStart(state) {
      state.loading = true
    },
    loadingEnd(state) {
      state.loading = false
    },
    updateCheckout(state, { payload }) {
      state.loading = false
      state.checkout = payload
    },
    openCart(state) {
      state.open = true
    },
    closeCart(state) {
      state.open = false
    },
  },
  extraReducers: builder => {
    builder
      .addCase(initializeCheckout.pending, (state, action) => {
        if (!state.loading) state.loading = true
      })
      .addCase(initializeCheckout.fulfilled, (state, { payload }) => {
        state.checkout = payload as any
        state.loading = false
      })
      .addCase(updateLineItem.pending, state => {
        if (!state.loading) state.loading = true
      })
      .addCase(updateLineItem.fulfilled, (state, { payload }) => {
        state.checkout = payload
        state.loading = false
      })
      .addCase(updateLineItem.rejected, state => {
        state.loading = false
      })
      .addCase(addLineItem.pending, state => {
        if (!state.loading) state.loading = true
      })
      .addCase(addLineItem.fulfilled, (state, action) => {
        state.checkout = action.payload
        state.loading = false
      })
      .addCase(addLineItem.rejected, state => {
        state.loading = false
      })
  },
})

export const { updateCheckout, openCart, closeCart, loadingStart, loadingEnd } =
  cartSlice.actions

// =====================================
//         ACTIONS
// =====================================

export const removeLineItem =
  (variantId: string) => async (dispatch: Dispatch) => {
    const checkoutId = getCheckoutId()

    const lineItemsToRemove = [variantId]

    const newCheckout = await client.checkout.removeLineItems(
      checkoutId,
      lineItemsToRemove
    )

    dispatch(updateCheckout(newCheckout))
  }

export default cartSlice.reducer
