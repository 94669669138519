/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

import Header from "../Header"
import Footer from "../Footer"

import "./layout.css"
import Cart from "../../features/cart/Cart"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {/* <Cart /> */}
      {/* pt-4 mt-20 md:mt-24 2xl:mt-28 */}
      <div className="centered-container">{children}</div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
