import React from "react"
import { Link } from "gatsby"
const Footer = () => {
  return (
    <div className="bg-blue-50">
      <div className="pt-12 mt-16 text-blue-900 md:mt-24 md:pt-16 centered-container">
        <div className="grid items-end grid-cols-1 gap-4 md:gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
          <div className="flex flex-col gap-y-4">
            <h2 className="text-lg">Pages</h2>
            <Link to="/">Home</Link>
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
          </div>
          <div className="flex flex-col gap-y-4">
            <Link to="/store">Store</Link>
            <Link to="/get-started#contact">Contact</Link>
            <Link to="/get-started">Get started</Link>
          </div>
          <div className="flex flex-col gap-y-1">
            <h2 className="text-lg">
              <Link to="/get-started#contact">Contact us</Link>
            </h2>
            <span>Smart Energy of New England Inc.</span>
            <span>PO Box 56, Colebrook NH, 03576</span>
            <span>info@smartenergyne.com</span>
            <span>1-800-608-5840</span>
          </div>
        </div>
        <div className="flex justify-center py-4 text-sm text-blue-800 md:pt-8">
          <span>
            &copy; 2022 Smart Energy Inc. Website by{" "}
            <a
              className="underline"
              target="_blank"
              href="https://jacobbelanger.com"
              rel="noreferrer"
            >
              Jacob Belanger
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Footer
