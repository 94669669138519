import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../tailwind.config.js"
import { useEffect, useState } from "react"

const config = resolveConfig(tailwindConfig)

const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]))

const breakpoints = objectMap(config.theme.screens, v =>
  parseInt(v.replace("px", ""), 10)
)

export const useBreakpoint = watch => {
  const [bpActive, setBpActive] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setBpActive(window.innerWidth >= breakpoints[watch])
    }

    window.addEventListener("resize", handleResize)
    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [watch])

  return bpActive
}
