import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { overrideTailwindClasses } from "tailwind-override"

const Button = ({
  children,
  type,
  to,
  href,
  className,
  loading = false,
  btnType = "button",
  ...props
}) => {
  const baseStyles = `
    flex px-10 py-3 
    rounded 
    hover:shadow-md hover:-translate-y-0.5 
    disabled:hover:shadow-none disabled:hover:translate-y-0 disabled:pointer-events-none disabled:opacity-80
    transition text-lg w-max items-center xl:text-xl justify-center
    after:transition-all after:block after:h-6 after:bg-loading-cta after:bg-contain after:bg-no-repeat after:bg-center 
    `
  const typeStyles = {
    cta: `cta-gradient py-4 text-gray-900`,
    "cta-small": `cta-gradient text-gray-900 text-base px-6 py-3 xl:text-base `,
    small: `bg-white text-blue-700 text-base px-6 py-3 xl:text-base disabled:pointer-events-none disabled:text-gray-400`,
    alt: `block p-4 md:py-3 xl:text-lg 
    bg-blue-50 text-blue-800 mx-4 md:mr-0 rounded 2xl:px-8`,
  }

  const notLoadingStyles = `after:w-0 after:duration-200`
  const loadingStyles = `hover:translate-y-0 hover:shadow-none opacity-80 cursor-default  after:w-6 after:ml-3 after:duration-500`

  const classes = overrideTailwindClasses(
    `${baseStyles} ${typeStyles[type || "regular"]} ${
      loading ? loadingStyles : notLoadingStyles
    } ${className}`
  )

  if (to)
    return (
      <Link to={to} {...props} className={classes}>
        {children}
      </Link>
    )
  if (href)
    return (
      <a href={href} {...props} className={classes}>
        {children}
      </a>
    )
  return (
    <button {...props} className={classes} type={btnType}>
      {children}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
}

Button.defaultPropTypes = {
  type: ``,
}

export default Button
